import type { Schemas } from '#shopware';

export const useFlyoutMenu = () => {
    const route = useRoute();
    const { pageBreadcrumbs, page, product } = usePage();

    const lastPageBreadcrumbItem = computed(() =>
        pageBreadcrumbs.value?.length ? pageBreadcrumbs.value[pageBreadcrumbs.value.length - 1] : null,
    );
    const categoryIdPath = computed(() => {
        if (!page.value?.category?.path) return null;
        return page.value.category.path + '|' + page.value.category.id;
    });
    const productCategoryPath = computed(() => {
        if (!product.value?.seoCategory) return null;
        return product.value.seoCategory.path + '|' + product.value.seoCategory.id;
    });

    const isActivePath = (navItem: Schemas['Category']) => {
        const categorySeoPath = navItem.seoUrls?.[0]?.seoPathInfo;
        const categoryId = navItem.id;

        if (categorySeoPath && lastPageBreadcrumbItem.value?.seoPath) {
            return lastPageBreadcrumbItem.value.seoPath.includes(categorySeoPath);
        } else if (categoryIdPath.value) {
            return categoryIdPath.value.includes(categoryId);
        } else if (productCategoryPath.value) {
            return productCategoryPath.value.includes(categoryId);
        }

        return categorySeoPath ? route.path.includes(categorySeoPath) : false;
    };

    return {
        isActivePath,
    };
};
